import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Dwars door Vlaanderen Dames",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-29 18:58:42",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21872: {
        teamId: "21872",
        teamUciCode: "FDJ",
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        teamNationCode: "FRA",
      },
      21877: {
        teamId: "21877",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21881: {
        teamId: "21881",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      22296: {
        teamId: "22296",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      22809: {
        teamId: "22809",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23744: {
        teamId: "23744",
        teamUciCode: "CSR",
        teamName: "CANYON//SRAM Racing",
        teamNationCode: "GER",
      },
      24281: {
        teamId: "24281",
        teamUciCode: "AGI",
        teamName: "AG Insurance-NXTG Team",
        teamNationCode: "NED",
      },
      24283: {
        teamId: "24283",
        teamUciCode: "PHV",
        teamName: "Parkhotel Valkenburg",
        teamNationCode: "NED",
      },
      27553: {
        teamId: "27553",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      27874: {
        teamId: "27874",
        teamUciCode: "LIV",
        teamName: "Liv Racing Xstra",
        teamNationCode: "NED",
      },
      27895: {
        teamId: "27895",
        teamUciCode: "JVW",
        teamName: "Team Jumbo-Visma",
        teamNationCode: "NED",
      },
      27898: {
        teamId: "27898",
        teamUciCode: "ARK",
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        teamNationCode: "FRA",
      },
      27926: {
        teamId: "27926",
        teamUciCode: "TIB",
        teamName: "EF Education-TIBCO-SVB",
        teamNationCode: "USA",
      },
      27951: {
        teamId: "27951",
        teamUciCode: "LEW",
        teamName: "Le Col Wahoo",
        teamNationCode: "GBR",
      },
      28095: {
        teamId: "28095",
        teamUciCode: "COF",
        teamName: "Cofidis Women Team",
        teamNationCode: "FRA",
      },
      28260: {
        teamId: "28260",
        teamUciCode: "HPW",
        teamName: "Human Powered Health",
        teamNationCode: "USA",
      },
      28364: {
        teamId: "28364",
        teamUciCode: "ASC",
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        teamNationCode: "LUX",
      },
      28368: {
        teamId: "28368",
        teamUciCode: "IBC",
        teamName: "IBCT",
        teamNationCode: "IRL",
      },
      28433: {
        teamId: "28433",
        teamUciCode: "CGS",
        teamName: "Roland Cogeas Edelweiss Squad",
        teamNationCode: "SUI",
      },
      28537: {
        teamId: "28537",
        teamUciCode: "PLP",
        teamName: "Plantur-Pura",
        teamNationCode: "BEL",
      },
      28572: {
        teamId: "28572",
        teamUciCode: "BCV",
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        teamNationCode: "BEL",
      },
      28573: {
        teamId: "28573",
        teamUciCode: "MUL",
        teamName: "Multum Accountants Ladies Cycling Team",
        teamNationCode: "BEL",
      },
      28954: {
        teamId: "28954",
        teamUciCode: "VAL",
        teamName: "Valcar-Travel & Service",
        teamNationCode: "ITA",
      },
      28991: {
        teamId: "28991",
        teamUciCode: "UAD",
        teamName: "UAE Team ADQ",
        teamNationCode: "UAE",
      },
    },
    riders: {
      89824: {
        id: 89824,
        startno: 102,
        firstName: "Leah",
        lastName: "Kirchmann",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-06-30",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89826: {
        id: 89826,
        startno: 105,
        firstName: "Liane",
        lastName: "Lippert",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89827: {
        id: 89827,
        startno: 101,
        firstName: "Floortje",
        lastName: "Mackaij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-10-18",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89523: {
        id: 89523,
        startno: 114,
        firstName: "Eugenia",
        lastName: "Bujak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-06-25",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      91959: {
        id: 91959,
        startno: 52,
        firstName: "Brodie",
        lastName: "Chapman",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89870: {
        id: 89870,
        startno: 184,
        firstName: "Eider",
        lastName: "Merino",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-02",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      157459: {
        id: 157459,
        startno: 175,
        firstName: "Megan",
        lastName: "Armitage",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1996-08-12",
        teamId: 28368,
        teamName: "IBCT",
        stillInTheRace: "Y",
      },
      96745: {
        id: 96745,
        startno: 216,
        firstName: "Rosalie",
        lastName: "Van Der Wolf",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-03-11",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      98961: {
        id: 98961,
        startno: 154,
        firstName: "Olha",
        lastName: "Kulynych",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "2000-02-01",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      155468: {
        id: 155468,
        startno: 176,
        firstName: "Haylee",
        lastName: "Fuller",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2003-10-16",
        teamId: 28368,
        teamName: "IBCT",
        stillInTheRace: "Y",
      },
      89763: {
        id: 89763,
        startno: 144,
        firstName: "Pauline",
        lastName: "Allin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-05-02",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89470: {
        id: 89470,
        startno: 112,
        firstName: "Sofia",
        lastName: "Bertizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-08-21",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      154491: {
        id: 154491,
        startno: 201,
        firstName: "Maureen",
        lastName: "Arens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-04-16",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      89806: {
        id: 89806,
        startno: 191,
        firstName: "Fien",
        lastName: "Delbaere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-04-21",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      131572: {
        id: 131572,
        startno: 236,
        firstName: "Margaux",
        lastName: "Vigie",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-07-21",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      57567: {
        id: 57567,
        startno: 66,
        firstName: "Eri",
        lastName: "Yonamine",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1991-04-25",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      96711: {
        id: 96711,
        startno: 126,
        firstName: "Amalie",
        lastName: "Lutro",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2000-03-15",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89886: {
        id: 89886,
        startno: 226,
        firstName: "Julie",
        lastName: "Van De Velde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-02",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      96836: {
        id: 96836,
        startno: 203,
        firstName: "Amelia",
        lastName: "Sharpe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-07-24",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      156233: {
        id: 156233,
        startno: 32,
        firstName: "Maud",
        lastName: "Oudeman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-09-29",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      154244: {
        id: 154244,
        startno: 83,
        firstName: "Gulnaz",
        lastName: "Khatuntseva",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1994-04-21",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      96036: {
        id: 96036,
        startno: 5,
        firstName: "Sarah",
        lastName: "Gigante",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-10-06",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89554: {
        id: 89554,
        startno: 51,
        firstName: "Cecilie Uttrup",
        lastName: "Ludwig",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-08-23",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      96729: {
        id: 96729,
        startno: 172,
        firstName: "Lena Charlotte",
        lastName: "Rei\u00c3\u009fner",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-11-14",
        teamId: 28368,
        teamName: "IBCT",
        stillInTheRace: "Y",
      },
      96698: {
        id: 96698,
        startno: 212,
        firstName: "Lieke",
        lastName: "Nooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-07-20",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      186806: {
        id: 186806,
        startno: 86,
        firstName: "L\u00c3\u00a9a",
        lastName: "Stern",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-09-25",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89426: {
        id: 89426,
        startno: 93,
        firstName: "Georgia",
        lastName: "Baker",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-09-21",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      96734: {
        id: 96734,
        startno: 55,
        firstName: "Marie",
        lastName: "Le Net",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-01-25",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      103237: {
        id: 103237,
        startno: 64,
        firstName: "Barbara",
        lastName: "Malcotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-02-19",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      93494: {
        id: 93494,
        startno: 155,
        firstName: "Claudia",
        lastName: "Jongerius",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-12-02",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      100601: {
        id: 100601,
        startno: 43,
        firstName: "Emma",
        lastName: "Langley",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1995-11-23",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89807: {
        id: 89807,
        startno: 71,
        firstName: "Valerie",
        lastName: "Demey",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-01-17",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      96692: {
        id: 96692,
        startno: 162,
        firstName: "Victoire",
        lastName: "Berteau",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-08-16",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      89983: {
        id: 89983,
        startno: 231,
        firstName: "Alice Maria",
        lastName: "Arzuffi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      93682: {
        id: 93682,
        startno: 221,
        firstName: "Inge",
        lastName: "Van der Heijden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-08-12",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      93467: {
        id: 93467,
        startno: 195,
        firstName: "Julie",
        lastName: "Stockman",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-08-17",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      89535: {
        id: 89535,
        startno: 121,
        firstName: "Hannah",
        lastName: "Barnes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-05-04",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89832: {
        id: 89832,
        startno: 16,
        firstName: "Ellen",
        lastName: "Van Dijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-02-11",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      95291: {
        id: 95291,
        startno: 74,
        firstName: "Marta",
        lastName: "Jaskulska",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "2000-03-25",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      90063: {
        id: 90063,
        startno: 44,
        firstName: "Sara",
        lastName: "Poidevin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1996-05-07",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      114479: {
        id: 114479,
        startno: 81,
        firstName: "Hannah",
        lastName: "Buch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2002-09-11",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89959: {
        id: 89959,
        startno: 94,
        firstName: "Nina",
        lastName: "Kessler",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1988-07-04",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89525: {
        id: 89525,
        startno: 2,
        firstName: "Jelena",
        lastName: "Eric",
        nationCode: "SBA",
        nationName: "Serbia",
        birthDate: "1996-01-15",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89432: {
        id: 89432,
        startno: 35,
        firstName: "Sarah",
        lastName: "Roy",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-02-27",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89514: {
        id: 89514,
        startno: 4,
        firstName: "Lourdes",
        lastName: "Oyarbide",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-04-08",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      98967: {
        id: 98967,
        startno: 133,
        firstName: "Zsofia",
        lastName: "Szabo",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1997-01-03",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      94111: {
        id: 94111,
        startno: 215,
        firstName: "Kirstie",
        lastName: "Van Haaften",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-01-21",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      102007: {
        id: 102007,
        startno: 141,
        firstName: "Yuliia",
        lastName: "Biriukova",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1998-01-24",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89431: {
        id: 89431,
        startno: 166,
        firstName: "Rachel",
        lastName: "Neylan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1982-03-09",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      98950: {
        id: 98950,
        startno: 142,
        firstName: "Morgane",
        lastName: "Coston",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-12-28",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89443: {
        id: 89443,
        startno: 111,
        firstName: "Marta",
        lastName: "Bastianelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-04-30",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      95824: {
        id: 95824,
        startno: 196,
        firstName: "Celine",
        lastName: "Van Houtum",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-03-12",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      90111: {
        id: 90111,
        startno: 163,
        firstName: "Alana",
        lastName: "Castrique",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-05-08",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      104035: {
        id: 104035,
        startno: 143,
        firstName: "Amandine",
        lastName: "Fouquenet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-02-19",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89448: {
        id: 89448,
        startno: 36,
        firstName: "Soraya",
        lastName: "Paladin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      93003: {
        id: 93003,
        startno: 125,
        firstName: "Mie Bj\u00c3\u00b8rndal",
        lastName: "Ottestad",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-07-17",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      164720: {
        id: 164720,
        startno: 224,
        firstName: "Justine",
        lastName: "Ghekiere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-05-14",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      154301: {
        id: 154301,
        startno: 186,
        firstName: "Alice",
        lastName: "Towers",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2002-10-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      90025: {
        id: 90025,
        startno: 26,
        firstName: "Karlijn",
        lastName: "Swinkels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-10-28",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      138754: {
        id: 138754,
        startno: 82,
        firstName: "Tamara",
        lastName: "Dronova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1993-08-13",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89480: {
        id: 89480,
        startno: 6,
        firstName: "Arlenis",
        lastName: "Sierra",
        nationCode: "CUB",
        nationName: "Cuba",
        birthDate: "1992-12-07",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      96710: {
        id: 96710,
        startno: 136,
        firstName: "Friederike",
        lastName: "Stern",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2001-02-15",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      96719: {
        id: 96719,
        startno: 211,
        firstName: "Pien",
        lastName: "Limpens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-02-24",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      92095: {
        id: 92095,
        startno: 33,
        firstName: "Elise",
        lastName: "Chabbey",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-04-24",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89425: {
        id: 89425,
        startno: 92,
        firstName: "Jessica",
        lastName: "Allen",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-04-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      90105: {
        id: 90105,
        startno: 53,
        firstName: "Ma\u00c3\u00ablle",
        lastName: "Grosset\u00c3\u00aate",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-04-10",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89776: {
        id: 89776,
        startno: 46,
        firstName: "Letizia",
        lastName: "Borghesi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-10-16",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      93393: {
        id: 93393,
        startno: 156,
        firstName: "Danique",
        lastName: "Braam",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-09-05",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      177299: {
        id: 177299,
        startno: 42,
        firstName: "Veronica",
        lastName: "Ewers",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-09-01",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89976: {
        id: 89976,
        startno: 134,
        firstName: "Sarah",
        lastName: "Rijkes",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-04-02",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      89927: {
        id: 89927,
        startno: 146,
        firstName: "Greta",
        lastName: "Richioud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-10-11",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      90862: {
        id: 90862,
        startno: 161,
        firstName: "Martina",
        lastName: "Alzini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-10",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      96706: {
        id: 96706,
        startno: 206,
        firstName: "Eline",
        lastName: "Van Rooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-08-29",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      114789: {
        id: 114789,
        startno: 193,
        firstName: "Sara",
        lastName: "Maes",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2002-01-23",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      96817: {
        id: 96817,
        startno: 106,
        firstName: "Pfeiffer",
        lastName: "Georgi",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-09-27",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89823: {
        id: 89823,
        startno: 11,
        firstName: "Lucinda",
        lastName: "Brand",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-07-02",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      112048: {
        id: 112048,
        startno: 132,
        firstName: "Georgia",
        lastName: "Danford",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1999-04-03",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      96695: {
        id: 96695,
        startno: 31,
        firstName: "Shari",
        lastName: "Bossuyt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-09-05",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      90101: {
        id: 90101,
        startno: 41,
        firstName: "Tanja",
        lastName: "Erath",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-10-07",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      90013: {
        id: 90013,
        startno: 76,
        firstName: "Eva",
        lastName: "Buurman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-09-07",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      168313: {
        id: 168313,
        startno: 115,
        firstName: "Alena",
        lastName: "Ivanchenko",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "2003-11-16",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89708: {
        id: 89708,
        startno: 15,
        firstName: "Lauretta",
        lastName: "Hanson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-10-29",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89597: {
        id: 89597,
        startno: 3,
        firstName: "Sheyla",
        lastName: "Gutierrez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-01-01",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      93088: {
        id: 93088,
        startno: 225,
        firstName: "Laura",
        lastName: "S\u00c3\u00bc\u00c3\u009femilch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-02-23",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      96773: {
        id: 96773,
        startno: 214,
        firstName: "Julia",
        lastName: "Van Bokhoven",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-04-01",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      96702: {
        id: 96702,
        startno: 124,
        firstName: "Wilma",
        lastName: "Olausson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-04-09",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      112278: {
        id: 112278,
        startno: 96,
        firstName: "Ruby",
        lastName: "Roseman-Gannon",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-11-08",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89825: {
        id: 89825,
        startno: 104,
        firstName: "Juliette",
        lastName: "Labous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-11-04",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89496: {
        id: 89496,
        startno: 75,
        firstName: "Katia",
        lastName: "Ragusa",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-19",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      99103: {
        id: 99103,
        startno: 194,
        firstName: "Elisa",
        lastName: "Serne",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-09-22",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      114495: {
        id: 114495,
        startno: 103,
        firstName: "Megan",
        lastName: "Jastrab",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2002-01-29",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      125562: {
        id: 125562,
        startno: 153,
        firstName: "Naomi",
        lastName: "De Roeck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-10-23",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      100561: {
        id: 100561,
        startno: 185,
        firstName: "Gladys",
        lastName: "Verhulst",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-01-02",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      90889: {
        id: 90889,
        startno: 233,
        firstName: "Chiara",
        lastName: "Consonni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-05-22",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89951: {
        id: 89951,
        startno: 122,
        firstName: "Susanne",
        lastName: "Andersen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1998-07-23",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      114776: {
        id: 114776,
        startno: 223,
        firstName: "Julie",
        lastName: "De Wilde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2002-12-08",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      99090: {
        id: 99090,
        startno: 183,
        firstName: "April",
        lastName: "Tacey",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-10-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      89421: {
        id: 89421,
        startno: 23,
        firstName: "Anouska",
        lastName: "Koster",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-08-20",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      91079: {
        id: 91079,
        startno: 182,
        firstName: "Maria",
        lastName: "Martins",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1999-07-09",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      97208: {
        id: 97208,
        startno: 234,
        firstName: "Olivia",
        lastName: "Baril",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1997-10-10",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      96725: {
        id: 96725,
        startno: 123,
        firstName: "Hannah",
        lastName: "Ludwig",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-02-15",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89522: {
        id: 89522,
        startno: 113,
        firstName: "Maaike",
        lastName: "Boogaard",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96857: {
        id: 96857,
        startno: 25,
        firstName: "Noemi",
        lastName: "R\u00c3\u00bcegg",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-04-19",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89433: {
        id: 89433,
        startno: 91,
        firstName: "Amanda",
        lastName: "Spratt",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1987-09-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      92774: {
        id: 92774,
        startno: 174,
        firstName: "Antonia",
        lastName: "Gr\u00c3\u00b6ndahl",
        nationCode: "FIN",
        nationName: "Finland",
        birthDate: "1995-05-25",
        teamId: 28368,
        teamName: "IBCT",
        stillInTheRace: "Y",
      },
      89745: {
        id: 89745,
        startno: 13,
        firstName: "Elisa",
        lastName: "Longo Borghini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-12-10",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      94113: {
        id: 94113,
        startno: 151,
        firstName: "Minke",
        lastName: "Bakker",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-03-11",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      89801: {
        id: 89801,
        startno: 14,
        firstName: "Leah",
        lastName: "Thomas",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-05-29",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      166448: {
        id: 166448,
        startno: 202,
        firstName: "Cassia",
        lastName: "Boglio",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-07-01",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      93081: {
        id: 93081,
        startno: 145,
        firstName: "Ana\u00c3\u00afs",
        lastName: "Morichon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-10-06",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89454: {
        id: 89454,
        startno: 116,
        firstName: "Anna",
        lastName: "Trevisi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-08",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      156241: {
        id: 156241,
        startno: 205,
        firstName: "Yuli",
        lastName: "Van Der Molen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-05-23",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      182339: {
        id: 182339,
        startno: 192,
        firstName: "Marthe",
        lastName: "Goossens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2002-05-04",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      91096: {
        id: 91096,
        startno: 45,
        firstName: "Elizabeth",
        lastName: "Banks",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-11-07",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89434: {
        id: 89434,
        startno: 1,
        firstName: "Annemiek",
        lastName: "van Vleuten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-10-08",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89819: {
        id: 89819,
        startno: 34,
        firstName: "Mikayla",
        lastName: "Harvey",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-09-07",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      101796: {
        id: 101796,
        startno: 222,
        firstName: "Sanne",
        lastName: "Cant",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-10-08",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      150795: {
        id: 150795,
        startno: 24,
        firstName: "Linda",
        lastName: "Riedmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2003-03-23",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      91011: {
        id: 91011,
        startno: 62,
        firstName: "Evy",
        lastName: "Kuijpers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-02-15",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      97812: {
        id: 97812,
        startno: 84,
        firstName: "Diana",
        lastName: "Klimova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-10-08",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89446: {
        id: 89446,
        startno: 12,
        firstName: "Chloe",
        lastName: "Hosking",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-10-01",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89850: {
        id: 89850,
        startno: 165,
        firstName: "Pernille",
        lastName: "Mathiesen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-10-05",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      123662: {
        id: 123662,
        startno: 173,
        firstName: "Svenja",
        lastName: "Betz",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1995-09-15",
        teamId: 28368,
        teamName: "IBCT",
        stillInTheRace: "Y",
      },
      110343: {
        id: 110343,
        startno: 213,
        firstName: "Quinty",
        lastName: "Schoens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-02-12",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89739: {
        id: 89739,
        startno: 54,
        firstName: "Emilia",
        lastName: "Fahlin",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1988-10-24",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89430: {
        id: 89430,
        startno: 95,
        firstName: "Alexandra",
        lastName: "Manly",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-28",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89925: {
        id: 89925,
        startno: 56,
        firstName: "Victorie",
        lastName: "Guilman",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-03-25",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89595: {
        id: 89595,
        startno: 72,
        firstName: "Rachele",
        lastName: "Barbieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-21",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      94463: {
        id: 94463,
        startno: 171,
        firstName: "Loes",
        lastName: "Adegeest",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-08-07",
        teamId: 28368,
        teamName: "IBCT",
        stillInTheRace: "Y",
      },
      178342: {
        id: 178342,
        startno: 63,
        firstName: "Makayla",
        lastName: "MacPherson",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2003-09-17",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      176998: {
        id: 176998,
        startno: 152,
        firstName: "Lotte",
        lastName: "Claes",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-05-05",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      98849: {
        id: 98849,
        startno: 164,
        firstName: "Valentine",
        lastName: "Fortin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-04-24",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      97831: {
        id: 97831,
        startno: 232,
        firstName: "Anastasia",
        lastName: "Carbonari",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-09-11",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      176891: {
        id: 176891,
        startno: 21,
        firstName: "Amber",
        lastName: "Kraak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-07-29",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      92988: {
        id: 92988,
        startno: 204,
        firstName: "Lone",
        lastName: "Meertens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-16",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      89638: {
        id: 89638,
        startno: 181,
        firstName: "Anna",
        lastName: "Christian",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-08-06",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      97542: {
        id: 97542,
        startno: 22,
        firstName: "Teuntje",
        lastName: "Beekhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-08-18",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      99002: {
        id: 99002,
        startno: 235,
        firstName: "Karolina",
        lastName: "Kumiega",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1999-04-16",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "DDV"
  const raceID = 9309

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
